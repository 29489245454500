import React, { useEffect, useState, useCallback } from 'react';
import './Hero.css';
import mainLogo from '../img/home/logo.svg';

const Hero = () => {
  // Stable calculateTimeLeft function using useCallback
  const hackathonDate = new Date('2024-11-14T00:00:00'); // Your hackathon start date
  
  const calculateTimeLeft = useCallback(() => {
    const now = new Date();
    const difference = hackathonDate - now;
    
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }
    return timeLeft;
  }, [hackathonDate]);

  // State to track time left
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft);

  // Load the Devfolio SDK script dynamically
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://apply.devfolio.co/v2/sdk.js';
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  // Countdown timer logic
  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer); // Clean up the timer
  }, [calculateTimeLeft]);

  return (
    <section className="hero">
      <div className="hero-heading">
        <img src={mainLogo} alt="Quantamaze Logo" />
        <h2>A 36-Hour Hackathon</h2>
      </div>
      <div className="apply-button-container" style={{ height: '44px', width: '312px', marginTop: '1rem' }}>
        <div 
          className="apply-button" 
          data-hackathon-slug="quantamaze-2" 
          data-button-theme="dark-inverted"
          style={{ height: '44px', width: '312px' }}
        ></div>
      </div>

      <div className="hero-subheading">
        <div className="event-date">
          November 14 - 16
        </div>
        <div className="event-venue">
          Nitte Meenakshi Institute of Technology, Bangalore
        </div>
      </div>

      {/* Countdown Section */}
      <div className="countdown-container">
        <h3>Days remaining for hackathon</h3>
        <div className="timer-container">
          <div className="timer-box">
            <h2>{timeLeft.days || 0}</h2>
            <span>Days</span>
          </div>
          <div className="timer-box">
            <h2>{timeLeft.hours || 0}</h2>
            <span>Hours</span>
          </div>
          <div className="timer-box">
            <h2>{timeLeft.minutes || 0}</h2>
            <span>Minutes</span>
          </div>
          <div className="timer-box">
            <h2>{timeLeft.seconds || 0}</h2>
            <span>Seconds</span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
